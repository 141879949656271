<template>
  <div id="margeTracking" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page T-size-36">Marge Tracking</div>
      </div>
    </div>
    <div id="tableData" class="wrap-Main">
      <div class="box-S4">    
        <v-btn small @click="syncdata()">Normal</v-btn>
        <br />
        {{this.items.length}}
        <div
          v-for="item in items"
          :key="item"
        >{{item.f_id}} {{item.aircrafts.ac_name}} {{item.aircrafts.ac_unit_name}} {{item.ap_depart_date}} {{item.ap_dest_date}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import feathersClientSkytrack from "../plugins/feathers-client-skytrack";
import feathersClientUOA from "../plugins/feathers-client-uoa";
import { startOfDay, endOfDay, format, addHours, addMinutes } from "date-fns";

export default {
  data: () => ({
    items: [],
    itemsskytrack: []
  }),

  async mounted() {},
  methods: {
    async syncdata() {
      try {
        const q = {};
        q.retire = 0;
        q.$eager = "[aircrafts]";
        var res = await feathersClientUOA.service("flight").find({ query: q });
        this.items = res.data;
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    
      try {
        for (let index = 0; index < this.items.length; index++) {
          if (this.items[index].aircrafts.ac_unit_name != null) {
           // console.log(this.items[index].f_id);

            const qtracking = {
              t_assetname: this.items[index].aircrafts.ac_unit_name,
              t_posntime: {
                $gte: addHours(
                  addMinutes(new Date(this.items[index].ap_depart_date), -15),
                  7
                )
              },
              t_posntime: {
                $lte: addHours(
                  addMinutes(new Date(this.items[index].ap_dest_date), 10),
                  7
                )
              }
            };
            console.log(JSON.stringify(qtracking));
            var res = await feathersClientSkytrack
              .service("tracking")
              .find({ query: qtracking });
            this.itemsskytrack = res.data;

            for (
              let indexinsert = 0;
              indexinsert < this.itemsskytrack.length;
              indexinsert++
            ) {
              await feathersClientSkytrack.service("flighttracking").create({
                f_id: this.items[index].f_id,
                t_name: this.itemsskytrack[indexinsert].t_name,
                t_imei: this.itemsskytrack[indexinsert].t_imei,
                t_altitude: this.itemsskytrack[indexinsert].t_altitude,
                t_latitude: this.itemsskytrack[indexinsert].t_latitude,
                t_longitude: this.itemsskytrack[indexinsert].t_longitude,
                t_speed: this.itemsskytrack[indexinsert].t_speed,
                t_track: this.itemsskytrack[indexinsert].t_track,
                t_posntypedescription: this.itemsskytrack[indexinsert].t_posntypedescription,
                t_posntype: this.itemsskytrack[indexinsert].t_posntype,
                t_assetname: this.itemsskytrack[indexinsert].t_assetname,
                t_source: this.itemsskytrack[indexinsert].t_source,
                t_gpsmode: this.itemsskytrack[indexinsert].t_gpsmode,
                t_geodistance: this.itemsskytrack[indexinsert].t_geodistance,
                t_georef_deleted: this.itemsskytrack[indexinsert].t_georef_deleted,
                t_georef_ident: this.itemsskytrack[indexinsert].t_georef_ident,
                t_georef_longitude: this.itemsskytrack[indexinsert].t_georef_longitude,
                t_groref_latitude: this.itemsskytrack[indexinsert].t_groref_latitude,
                t_groref_name: this.itemsskytrack[indexinsert].t_groref_name,
                t_groref_prominence: this.itemsskytrack[indexinsert].t_groref_prominence,
                t_groref_region: this.itemsskytrack[indexinsert].t_groref_region,
                t_groref_specific: this.itemsskytrack[indexinsert].t_groref_specific,
                t_groref_geotype: this.itemsskytrack[indexinsert].t_groref_geotype,
                t_latitude_string: this.itemsskytrack[indexinsert].t_latitude_string,
                t_longitude_string: this.itemsskytrack[indexinsert].t_longitude_string,
                t_phonenumber: this.itemsskytrack[indexinsert].t_phonenumber,
                t_unitname: this.itemsskytrack[indexinsert].t_unitname,
                t_posntime: this.itemsskytrack[indexinsert].t_posntime,
                t_gatetime: this.itemsskytrack[indexinsert].t_gatetime
              });
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style>
</style>